import axios from '@/libs/api.request'
import wx from "weixin-js-sdk";

/**
 * 获取微信授权url
 * @returns {wx.RequestTask | never}
 */
export const getOauthUrl = (baseUrl, scope) => {
  return axios.request({
    url: 'OauthUrl',
    method: 'get',
    params: { url: baseUrl, scope: scope}
  })
}

export const getAppid = () => {
  return axios.request({
    url: '/v1/wx/appid',
    method: 'get',
    params: {}
  })
}

/**
 * 根据用户授权code 获取用户信息    新版
 * @returns {wx.RequestTask | never}
 */
 export const getAuUserInfo = (code, scope, state) => {
  return axios.request({
    url: 'v1/wx/index',
    method: 'get',
    params: {code: code, sns_scope: scope, silent: state}
  })
}

/**
 * 根据用户授权code 获取用户信息
 * @returns {wx.RequestTask | never}
 */
export const getSNSUserInfo = (code, scope) => {
  return axios.request({
    url: 'v1/wx/index',
    method: 'get',
    params: {code: code, sns_scope: scope}
  })
}

/**
 * 获取微信jssdk 配置
 */
export const wxJsConfig = (data) => {
  return axios.request({
    url: ' v1/wx/sdk',
    method: 'post',
    data: data
  })
}