import './features/common/libs/DateFormatPolyfill';
import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import Vant, { Lazyload } from 'vant';
import 'vant/lib/index.css';
Vue.use(Vant);
Vue.use(Lazyload);
// import '@/libs/amfe-flexible.js'//优化更改后的淘宝适配方案
// import 'lib-flexible'
import 'amfe-flexible'
import './assets/less/minireset.min.css'
import checkLogin from "./features/checkLogin";

import { wxInfo } from './api/user'
/** 
 * @description 全局注册应用配置
 */
import config from '@/config'

async function getwxInfo () {
  let res = await wxInfo()    // 获取公众号配置
  if(res.code === 200) {
    let result = res.data.data
    config.share.baseUrl = result.share_url
    config.title = result.title || "掌上理论"
    config.share.title = config.title + config.share.title
    config.share.imgData = result.qr_img
    config.share.kf_img = result.kf_img
    config.is_show_income = result.is_show_income
    config.theme_value =  result.theme_value
    config.km1_show = result.km1     // 0 是隐藏     1 是开启
    config.km3_show = result.km3     // 0 是隐藏     1 是开启
    config.profit_tab = result.is_show_revenue_navigation
    config.is_dst_reg_sms = result.is_dst_reg_sms   // 是否开启验证分销员
  }
  Vue.prototype.$config = config

  Vue.prototype.$setColor = setColor

  // 全局主题配置
  switch (Number(config.theme_value)) {
    case 1:
      Vue.prototype.$theme = config.theme.singleRow
      break;
    case 3:
      Vue.prototype.$theme = config.theme.fourthRow
      break;
    case 4:
      Vue.prototype.$theme = config.theme.thirdRow
    default:
      Vue.prototype.$theme = config.theme.original
      break;
  }
  //测试服自定义主题
  let wantTheme = sessionStorage.getItem('wantTheme')
  if (wantTheme) Vue.prototype.$theme = config.theme[wantTheme]
  setColor()

  // 防止IOS端签名失败
  sessionStorage.setItem('init_sign',1)
  sessionStorage.setItem('init_url',location.href)

  // 等用户授权完之后，再走路由
  checkLogin().then(() => {
    new Vue({
      router,
      store,
      render: h => h(App),
    }).$mount('#app')
  }).catch(() => { })  
}
getwxInfo()

//设置主题色
function setColor() {
  document.getElementsByTagName('body')[0].style.setProperty('--themeColor', Vue.prototype.$theme.themeColor);
  document.getElementsByTagName('body')[0].style.setProperty('--titleColor1', Vue.prototype.$theme.titleColor1);
  document.getElementsByTagName('body')[0].style.setProperty('--titleColor2', Vue.prototype.$theme.titleColor2);
}
