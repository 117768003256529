<template>
  <div>
    <div class="fixed inset-0 translate-z-0 bg-white/10 z-50" v-if="show" />
    <van-popup v-if="show" v-model="popup.show" @close="maskClick">
      <div class="fixed-box">
        <img src="../assets/images/sign_img.png" class="img-box" />
        <div class="abso-box">
          <div>恭喜您</div>
          <div>成功领取1积分</div>
        </div>
      </div>
    </van-popup>
  </div>
</template>

<script>
import { loginIntegral } from '@/api/user';

export default {
  components: {},

  data() {
    return {
      show: false,
      popup: {
        show: true,
      },
      me: this.$store.state.user.userInfo
    };
  },

  mounted() {
    if (this.me.user_type == 1 || this.me.is_regional == 1) return;
    let is_sign = localStorage.getItem('is_sign') || false,
      day_end = localStorage.getItem('day_end') || 0,
      day_sart = new Date().getTime();
    if (!is_sign || day_end - day_sart < 0) {
      loginIntegral().then((res) => {
        if (res.data.code == 1) {
          this.show = true;
          localStorage.setItem('is_sign', true);
          day_end = new Date(
            new Date(new Date().toLocaleDateString()).getTime() +
              24 * 60 * 60 * 1000 -
              1
          ).getTime();
          localStorage.setItem('day_end', day_end);
        }
      });
      setTimeout(() => {
        this.show = false;
      }, 2000)
    }
  },

  methods: {
    maskClick() {
      setTimeout(() => {
        this.show = false;
      }, 500);
    },
  },
};
</script>

<style lang="scss" scoped>
:deep(.van-popup) {
  background: transparent;
}
.fixed-box {
  width: 199px;
  height: 150px;
  position: relative;
  .img-box {
    width: 100%;
    height: 100%;
    display: block;
  }
  .abso-box {
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    margin-bottom: 16px;
    text-align: center;
    font-family: 'Source Han Sans CN-Bold';
    font-size: 22px;
    font-weight: bold;
    color: #e60a12;
  }
}
</style>
