import { getToken, setToken } from '@/libs/util';
import weixinAuthorize, { hasCodeAndState, snsapiUserInfo } from '@/features/weixin/authorize';
import axios from '@/libs/api.request'
import { authorize } from './weixin/config'
import store from '@/store'
import Vue from 'vue'
import Vconsole from 'vconsole'

// 非静默授权获取微信用户信息
export const weixinAuthorizeUserInfo = () => snsapiUserInfo({ state: 1 });

const checkLogin = async (isForceWeixinAuthorize = false) => {
  // localStorage.removeItem('default_city_id')
  // localStorage.removeItem('default_city_name')
  // 登录过期后，清除缓存token，强制授权登录
  if (isForceWeixinAuthorize) setToken("")

  let token = getToken();
  //开发者便利代码 仅开发环境使用
  if (process.env.NODE_ENV === "development") {
    token = sessionStorage.getItem('gzcd_web_ApiAuth')
    if (!token) token = prompt("检测到当前为开发环境 请先输入token");
    sessionStorage.setItem('gzcd_web_ApiAuth', token)
    setToken(token)
  }

  if (!token || isForceWeixinAuthorize || hasCodeAndState) {
    // 这里是因为多个公众号的问题，动态获取appid
    let scope
    if (!hasCodeAndState) {
      scope = sessionStorage.getItem('OauthScope') || 'snsapi_base';
      let spArr = location.hash.split('?'), baseUrl;
      // 兼容判断是否是旧科目一链接 hash
      if (location.hash) {
        baseUrl = encodeURIComponent(location.protocol + '//' +location.host + "?" + spArr[1])
        localStorage.setItem("km_type", 1)
      } else {
        let [, kmType] = location.href.match(/[?&]kmType=(\w+)/) || [];
        baseUrl = encodeURIComponent(location.protocol + '//' +location.host)
        localStorage.setItem("km_type", kmType || localStorage.getItem("km_type") || "")
      }
      await getOauthUrl(baseUrl, scope);
    }

    // 微信授权逻辑
    let res = await weixinAuthorize()

    // 判断是否是区代
    let [, use_nal] = location.href.match(/[?&]user_type=(\w+)/) || [];
    if (location.pathname == "/profit" && use_nal == 3) {
      sessionStorage.setItem('is_regional', 1)
      sessionStorage.setItem('user_type', 3)
    }

    // 是否是静默授权获取微信用户信息和token
    scope = res.state == 1 ? 'snsapi_userinfo' : 'snsapi_base'
    res = await getAuUserInfo(res.code, scope, res.state);
    setToken(res.data.token)
    token = res.data.token
  }

  // 获取绑定用户参数
  let [, , pid] = location.href.match(/[?&](shareMember|dst_id)=(\w+)/) || [];
  let [, , bindType] = location.href.match(/[?&](shareType|bind_type)=(\w+)/) || []; 

  // 学员 和 is_dst_reg_sms 不是 1 直接走绑定
  // if ((pid && bindType == 2 && Vue.prototype.$config.is_dst_reg_sms != 1) || (pid && bindType != 2)) {
    // bindType = bindType || 1
    // await bindSuper({ super_id: pid, type: bindType })
  // }

  // is_dst_reg_sms 等于 1 时，如果教练都先直接绑定学员； 否则就直接绑定教练或者学员
  let bind_t = 1;
  if (pid) {
    if (bindType == 2) {
      if (Vue.prototype.$config.is_dst_reg_sms != 1) {
        bind_t = bindType || 1
      } else {
        bind_t = 1
      }
    } else {
      bind_t = 1
    }
    
    await bindSuper({ super_id: pid, type: bind_t })
  }
  
  /**
   * 如果 bindType == 1，说明绑定是学员身份
   * 
   * 如果 bindType == 2，绑定教练上下级关系，当 is_dst_reg_sms == 0，直接绑定上下级关系，否则，
   * 跳转 /register 验证绑定上下级关系
   * 
   * 如果走招募链接，不管后台是否开启 is_dst_reg_sms 验证，直接走验证码注册
   */

  await Promise.all([getPrivate(), getUseInfo(), getMumberInfo()]);

  if (process.env.NODE_ENV != "development") {
    await store.dispatch('getWxConfig', { name: "掌上考场理论" })
    await store.dispatch('getLocation')
  }
  
  if (sessionStorage.getItem('loca_url')) {
    
  } else {
    if (bindType == 2 && Vue.prototype.$config.is_dst_reg_sms == 1 && location.search.indexOf('qrcode_id') == -1) {
      let h_url = location.origin + '/register' + location.search
      sessionStorage.setItem('loca_url', h_url);
      sessionStorage.setItem('bind_pid', pid);
      location.replace(h_url)
    }
  }

  return token
}

export default checkLogin;


// 获取授权code的url连接
const getOauthUrl = async (baseUrl, scope) => {
  let res = await axios.request({
    url: '/v1/wx/appid',
    method: 'get',
    params: {}
  })
  authorize.appid = res.data.data
  sessionStorage.setItem('appid', res.data.data)
  return res;
};

// 根据授权code获取 是否是静默或者非静默登录 state: 0 是,  1 否  改版
const getAuUserInfo = async (code, scope, state) => {
  let res = await axios.request({
    url: 'v1/wx/index',
    method: 'get',
    params: {code: code, sns_scope: scope, silent: state}
  })
  let time = Math.round(new Date().getTime() / 1000)
  if (res.code === 200) {
    if (res.data.info.is_debug != 0) {
      new Vconsole();
    }
    let regional = sessionStorage.getItem('is_regional') || 0
    if (regional && res.data.info.is_regional == 1) {
      res.data.info.user_type = 3
    }
    store.commit('setNewUser', res.data.info.is_new)
    store.commit('setUserInfo', res.data.info)
    localStorage.setItem('shareMember', res.data.info.id)
    sessionStorage.setItem('user_login_time', time);
    sessionStorage.setItem("user_type", res.data.info.user_type)
  }
  return res;
};

// 绑定用户
const bindSuper = async (data) => {
  if (sessionStorage.getItem('is_bind_share')) {
    return;
  }
  let res = await axios.request({
    url: 'v1/user/bind-user',
    method: 'post',
    data: data
  })
  sessionStorage.setItem('is_bind_share', 1)
  return res;
};

// 内测用户信息
const getPrivate = async (data) => {
  let res = await axios.request({
    url: '/v1/wx/km-module',
    method: 'get',
    params: data
  })
  store.commit('setKmModel', res.data.data)
  if (res.data.data.km1 != 1 || res.data.data.km3 != 1) {
    let k_t = res.data.data.km1 == 1 ? 1 : 2
    localStorage.setItem("km_type", k_t)
  }
  
  return res;
};

// 获取刷新token用户相关信息
const getUseInfo = async () => {
  let res = await axios.request({
    url: 'v1/wx/ref-token',
    method: 'get',
  })
  let time = Math.round(new Date().getTime() / 1000)
  if (res.code === 200) {
    if (res.data.member.is_debug != 0) {
      new Vconsole();
    }
    let regional = sessionStorage.getItem('is_regional') || 0
    if (regional && res.data.member.is_regional == 1) {
      res.data.member.user_type = 3
    }
    if (res.data.member.s_city != 0 && !localStorage.getItem('default_city_id')) {
      localStorage.setItem('default_city_id', res.data.member.s_city)
      let ad = res.data.member.s_city_title == "市辖区" ? '上海市' : res.data.member.s_city_title
      localStorage.setItem('default_city_name', ad)
    }
    store.dispatch('getOrSetShareHidInfo', { val: 0})
    store.commit('setNewUser', res.data.member.is_new)
    store.commit('setUserInfo', res.data.member)
    store.commit('setToken', res.data.token)
    sessionStorage.setItem('user_login_time', time);
    sessionStorage.setItem('user_type', res.data.member.user_type)
  }
  return res;
};

// 获取统一用户信息接口
const getMumberInfo = async () => {
  let res = await axios.request({
    url: 'v1/user/member-info',
    method: 'post',
    data: {}
  })
  let regional = sessionStorage.getItem('is_regional') || 0
  if (regional && res.data.info.is_regional == 1) {
    res.data.info.user_type = 3
  }
  store.commit('setUserInfo', res.data.info)
  store.commit('setKm1City', res.data.info.km1_city)
  return res;
};
