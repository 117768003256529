<template>
  <div id="app" :class="$theme.appName">
    <router-view v-if="isRouterAlive"></router-view>
    <wxAuthorzeUserInfoMask></wxAuthorzeUserInfoMask>
    <integralSignMask></integralSignMask>
  </div>
</template>

<script>
import wxAuthorzeUserInfoMask from '@/components/wxAuthorzeUserInfoMask.vue'
import integralSignMask from '@/components/integralSignMask.vue'

export default {
  name: 'App',
  components: {
    wxAuthorzeUserInfoMask,
    integralSignMask
  },
  provide() {
    return {
      reload: this.reload
    }
  },
  data() {
    return {
      no_share: ['lessonInfo', 'share', 'Invite'],//不需要全局分享的页面
      member: this.$store.state.user.userInfo,
      isRouterAlive: true
    }
  },
  mounted() {
    if (this.member.id) {
      this.share()
    }
  },

  destroyed() {
    if (sessionStorage.getItem("show_heat")) {
      sessionStorage.removeItem("show_heat")
    }
  },

  created() {
    //在页面加载时读取sessionStorage里的状态信息
    // if (sessionStorage.getItem("userInfo")) {
    //     // this.$store.replaceState(Object.assign({}, this.$store.state.user.userInfo, JSON.parse(sessionStorage.getItem("userInfo"))))
    //     this.$store.state.user.userInfo = JSON.parse(sessionStorage.getItem("userInfo"))
    // }
    // //在页面刷新时将vuex里的信息保存到sessionStorage里
    // window.addEventListener("beforeunload", () => {
    //     sessionStorage.setItem("userInfo", JSON.stringify(this.$store.state.user.userInfo))
    // })

    window.addEventListener('popstate', () => {
      if(sessionStorage.getItem("error_arr")) {
        this.$store.commit('ERROE_ARR',false)
      }
      if(sessionStorage.getItem("right_arr")) {
        this.$store.commit("RIGHT_ARR",false)
      }
      if(sessionStorage.getItem("qst_arr")) {
        sessionStorage.removeItem('qst_arr')
      }
      if(sessionStorage.getItem("grades")) {
        sessionStorage.removeItem('grades')
      }
      if(sessionStorage.getItem("qst_index")) {
        sessionStorage.removeItem('qst_index')
      }
      if(sessionStorage.getItem("qst_info")) {
        sessionStorage.removeItem("qst_info")
      }
      if(sessionStorage.getItem('zskc_ball')) {
        sessionStorage.removeItem('zskc_ball')
      }
      if(sessionStorage.getItem('zskc_timestamp')) {
        sessionStorage.removeItem('zskc_timestamp')
      }
      if(sessionStorage.getItem('answer_qst_arr')) {
        sessionStorage.removeItem('answer_qst_arr')
      }
      if(sessionStorage.getItem('answer_qst_info')) {
        sessionStorage.removeItem('answer_qst_info')
      }
    });

  },
  watch: {
    '$route.name': function () {
      if (this.member.id) {
        this.share()
      }
    },
    '$store.state.user.userInfo': function (userInfo) {
      if (!this.member.id) {
        this.member = userInfo
        this.share()
      }
    }
  },
  methods: {
    share() {
      if (this.no_share.indexOf(this.$route.name) == -1) {
        let km_tp = localStorage.getItem("km_type") == 1 ? 1 : 2
        let shareUrl = this.$config.share.baseUrl + '/?shareMember=' + this.member.id +'&kmType=' + km_tp 
        this.$store.dispatch('wxShare', { shareUrl: shareUrl, config: this.$config.share })
      }

      // 系统已关闭
      if (this.$config.km1_show != 1 && this.$config.km3_show != 1) {
        this.$router.push({path: "closeError"})
      }
    },
    reload() {
      this.isRouterAlive = false;
      this.$nextTick(() => {
        this.isRouterAlive = true
      })
    }
  }
}
</script>
<style lang="scss">
//@tailwind base;
// @tailwind components;
@tailwind utilities;

img {
  -webkit-touch-callout: none;
}

:root {
  --themeColor: #139bf9; //全局主题色
  --titleColor1: #4ab5fe; //标题栏主色
  --titleColor1: #ffb222; //标题栏副色
}

* {
  box-sizing: border-box;
}

input,
textarea {
  user-select: text;
  -webkit-touch-callout: default;
}

.clearfix::after {
  content: '';
  display: block;
  height: 0;
  clear: both;
  visibility: hidden;
}

.clearfix {
  zoom: 1;
}

html,
body {
  width: 100%;
  height: 100%;
  user-select: none;
  text-align: center;
}

#app {
  width: 100%;
  min-height: 100%;
  max-width: 750px;
  /* no */
  font-family: 'Avenir', Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  margin: 0 auto;
  font-size: 15px;
  color: #3d5266;
  background: #f7f8fa;
}

.suc {
  color: #26b120;
}

.err {
  color: #ff3a3a;
}

.light {
  color: #7f87a6;
}

.cash {
  color: #fba515;
}

//重置全局vant样式
.van-nav-bar__arrow,
.van-nav-bar__text {
  color: #697987 !important;
}

.van-nav-bar__title {
  font-weight: 500 !important;
  color: #3d5266 !important;
  font-size: 16px !important;
}
</style>